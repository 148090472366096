export default {
    header: {
        project: "项目",
        module: "模型",
        en: 'English',
        zh: '中文',
        changeLang: 'switch language',
        overview: '概览',
        task: '任务',
        guide: '使用指南',
        project1: '当前项目',
        task1: '当前任务',
        new: '新手指引',
        noTask: '未选择任务',
        noPorj: '未选择项目',
        userHome: '个人中心',
        loginOut: '退出登录',
        cantTO: '当前没有选择任务，无法跳转',
    },
    overview: {
        projieMsg: '项目信息',
        Resources: '项目资源',
        ProjectQuantity: '协同项目',
        TaskQuantity: '协同任务',
        inPro: '创建项目',
        inTask: '参与任务',
        picture: '资源使用情况',
        allImg: "总图片额度",
        allTrain: '总训练额度',
        upImgC: '已上传图片',
        modelC: "已训练次数",
        Spaceoccupancy: '空间占用',
        lastPro: '最近工程 ',
        newPro: '新建工程',
        proName: '工程名称',
        createTime: '创建时间',
        lastTime: '最后修改时间',
        Defect: '缺陷检测模块',
        continue: '继续',
        lastStudy: '最近训练',
        taskName: '任务名称',
        status: '训练状态',
        overTime: '完成时间',
        taskType: '任务类型',
        leftTime: '剩余时间',
        userName: '用户名',
        cheack: '查看'
    },
    project: {
        inupt: '请输入项目名',
        proname: '项目名',
        tasknum: '任务数',
        collaborate: '协作',
        ctime: '创建时间',
        newpro: '新建项目',
        projectNotes: '项目备注',
        proImg: '项目LOGO',
        TimeD: '按创建时间降序',
        TimeA: '按创建时间升序',
        NameD: '按项目名称降序',
        NameA: '按项目名称升序',
        enterProject: '请输入项目名称',
    },
    upload: {
        mark: '将图片拖到此处，或者选择下方logo，或者',
        or: '点击这里选取 ',
    },
    button: {
        cancel: '取 消',
        save: '保 存',
        sure: '确 定',
        del: '删 除',
        add: '添 加',
        edit: '修 改',
        up: '上 传',
        pre: '预 览',
        down: '下 载',
        back: '返 回',
        up1: '直接上传',
        upEdit: '截取图片上传'
    },
    task: {
        user: '创建人',
        proid: '项目ID',
        createTime: '创建时间',
        notes: '备注',
        userList: '协作名单',
        username: '用户名',
        time: '时间',
        edit: '操作',
        add: '添加协作',
        delete: '删除该项目',
        search: '请输入任务名',
        new: '新建任务',
        taskName: '任务名',
        imgNum: '图片数',
        unMarks: '未标注数',
        newTak: '新建任务',
        moduleNum: '模型数',
        ai: 'AI框架',
        imgType: '图像检测类型',
        logo: '任务LOGO',
        editPro: '编辑项目',
        role: '角色管理',
        auth: '是否开启审核',
        choose: '请选择',
        dingwei: '目标定位',
        dingwei1: '区域级，准确识别目标或缺陷',
        dingwei11: '多目标检测',
        dingwei12: '多类缺陷检测',
        fenge: '图像分割',
        fenge1: '像素级，适合找产品细节的外观缺陷',
        fenge11: '产品表面细小缺陷',
        fenge12: '复杂场景缺陷检测',
        fenlei: '图像分类',
        fenlei1: '图像级，判断整张图像所属类别',
        fenlei11: '农副产品的分类定级',
        fenlei12: '工业质检领域缺陷的分类',
        changjing: '应用场景',
        enterTask: '请输入任务名',
        selectType: '请选择图像检测类型',
        enterUser: '请输入用户登录ID',
        giveUser: '分配角色',
        xzxq: '协作详情',
        nowRole: '当前角色',
        noRole: '未分配',
        projMove: '项目移交',
        suerMp: '确认移交此项目吗？',
        img30: '近三十天标注图片数',
        markNum: '标注数',
        noName: '项目名称不能为空',
        noName1: '任务名称不能为空',
        user1: '用户',
        noHas: '不存在',
        sureAdd: '确定添加 ',
        ma: ' 协作吗？',
        sureDel: '确定删除 ',
        zzbz: '正在标注',
        shz: '审核中',
        dcb: '待重标',
        shtg: '审核通过',
        moveProj: "确定移交此项目吗？",
        wujiandu: '无监督学习',
        wjdText: '无需标注，只需上传正样本图片即可训练',
        wjd11: '产品瑕疵较为明显',
        wjd12: '产品瑕疵样品少',
    },
    role: {
        addRole: '新增角色',
        roleID: '角色ID',
        roleName: '角色名称',
        roleRight: '角色权限',
        notes: '备注',
        delete: '删除',
        edit: '修改',
        editTime: '修改时间',
        suerDelete: '确定删除该角色？',
        right: '权限',
        editRole: '修改角色',
        enterRole: '请输入角色名称',
        one: '请至少选择一个权限'
    },
    img: {
        tag1: '标签',
        tagNum: '标签数',
        state: '状态',
        use: '启用',
        nouse: '禁用',
        addTag: '添加标签',
        begin: '开始训练',
        delete: '删除该任务',
        clone: '复制该任务',
        img: '图集',
        module: '模型',
        all: '全部',
        marked: '已标注',
        unmark: '未标注',
        tagS: '标签筛选',
        allimg: '全部图片',
        imgnum: '图片数量',
        sort: '排序',
        timeD: '按上传时间降序',
        timeU: '按上传时间升序',
        nameD: '按图片名称降序',
        nameU: '按图片名称升序',
        look: '查看收藏图片',
        allin: '全选',
        deleteS: '删除选中',
        move: '移动到',
        open: '图片分类',
        close: '关闭分类',
        upImg: '上传图片',
        upMarkImg: '上传已标注图片',
        markNum: '标注数',
        editTask: '编辑任务',
        addClass: '在此分类下添加子分类',
        editClass: '编辑此分类',
        dClass: '删除此分类',
        fileName: '文件名:',
        uptime: '上传时间:',
        imgI: '图片尺寸:',
        last: '最后标注时间:',
        nomark: '暂未标注',
        imgSize: '图片大小',
        reject: '驳回原因',
        eTag: '编辑标签',
        tagn: '标签名',
        tagnc: '标签中文名',
        color: '颜色',
        editC: '编辑分类',
        addC: '添加分类',
        classN: '分类名',
        classI: '请输入分类名',
        upM1: '将文件压缩包拖到此处进行上传或',
        upts: '点击选择',
        upM2: '一个文件压缩包',
        onlyZip: '只能选择zip格式文件',
        ssC: '请选择训练使用的图片',
        ssT: '请选择训练使用的标签',
        selectPT: "请选择平台",
        gkj: '工控机',
        hz: '视觉AI控制器',
        other: '专业选项',
        size: '被检测目标大小',
        s1: '通用目标检测(极速)',
        s2: '一般目标(速度一般)',
        s3: '高精度小目标',
        many: '迭代次数',
        cloneTo: '任务复制至：',
        moveC: '移动分类',
        proL: '请选择要将该任务复制到哪个项目下(输入项目名可搜索)',
        deleteTask: '此操作将删除该任务, 是否继续?',
        deleteInfo: '将要删除的任务信息：',
        tagN: '标签数',
        classN1: '图片分类数量',
        modelN: '模型数量',
        sureD: '确认继续删除吗？',
        hasM: '此任务存在未训练完成的模型',
        isMove: '是否将选中图片移入此分类下？',
        isUpload: '是否上传图片到：',
        isUpload1: '是否上传压缩包图片到：',
        editRole: '无修改任务权限',
        editTRole: '无修改标签权限',
        addTRole: '无添加标签权限',
        trainRole: '无训练权限',
        deleteRole: '无删除任务权限',
        cloneRole: '无复制任务权限',
        over: '完成标注，申请审核',
        authing: '审核中',
        toauth: '去审核',
        reImg: '被驳回图片',
        pass: '审核通过',
        upRole: '无上传图片权限',
        zipTitle: '先选择分类，才能上传已经标注并打包好的压缩包文件',
        deImgRole: '无删除图片权限',
        word: '规范文档',
        clss: '点击任意图片框选区域',
        clsss: '禁止标注区域',
        selectClsTag: '请选择分类标签',
        onlyWord: '只能上传doc/docx/pdf格式的文件',
        downRole: '无下载文档权限',
        preRole: '无查看文档权限',
        delWordRole: '无删除文档权限',
        noword: '未上传规范文档',
        wordName: '文档名',
        upWtime: '上传时间',
        upUser: '上传用户',
        classAll: '将此分类下图片全部标注为',
        enterET: '请输入标签英文名',
        enterCN: '请输入标签中文名',
        selectC: '请选择颜色',
        moveTitle: '请拖拽鼠标或者点击选中图片，再点击‘移动到’按钮进行分类。',
        sureDeL: '确定要删除吗？',
        sureAu: '确认标注完成，申请审核吗？',
        hasUn: '还有图片未标注，确认申请审核吗？',
        noAu: '无审核权限',
        noImg: '无图片',
        noTag: '未选择标签',
        sureImg: '确认删除选中图片？',
        zipsize: '文件大小不能超过1G',
        noClass: '未分类',
        fenlei: '图像分类',
        onlyOne: '当前限制选择1个文件',
        onlyCn: '只能输入英文、中文、数字或者下划线_',
        train_name: '请输入模型名称，不输入默认使用任务名＋训练ID',
        onlySee: '无标注权限,只可查看',
        chooseUpType: '请选择上传图片类型',
        zyb: '正样本',
        ycyb: '异常样本', 
        nowImg: '当前图片为：',
    },
    upImg: {
        title1: '将图片文件拖到此处，或',
        title2: '点击选择图片上传',
        only: '只能上传jpg/png/jpeg/bmp文件，且不超过3000个文件',
        file: '点击选择文件夹上传',
        fileC: '文件个数：',
        fileN: '文件名',
        res: '结果',
        clear: "清除文件",
        t1: '上传成功图片已存在',
        t2: '上传成功',
        t3: '上传失败',
        t4: '不支持的图片类型',
        t5: '上传成功转为jpg保存',
        t6: '图片上传数量已达到当前用户最大权限',
        t7: '文件名称过长',
        t8: '该文件为空文件',
        uping: '正在上传',
        out: "文件数量超出3000个",
        kong: '该文件夹为空文件夹',
        noS: "请选择文件",
        noC: '请先清除文件',
        bzc: '不支持的图片类型',
        upS: '上传成功',
        upO: '上传完成',
        up: '上传',
        sizeError: '选择的图片尺寸大小不同，请清除图片后重新选择图片',
        kuang: '请拖动红色边框，框选要上传的区域',
        selectModel: '请选择一个模型',
        model: '使用AI模型自动裁剪图片后上传',
    },
    mark: {
        down: '下载当前图片',
        move: '更改此图片分类',
        lookall: '查看全部',
        looksame: '只看收藏',
        pre: '上一张',
        next: '下一张',
        b1: '平移',
        b2: '多边形绘制',
        b3: '矩形绘制',
        b4: '撤销操作',
        showAll: "查看全部",
        showAu: '查看未审核',
        showRe: '查看驳回',
        save: '保 存',
        sc: "收藏此图片",
        addTag: '添加标签',
        autoing: "正在进行AI自动标注训练",
        auto: 'AI自动标注',
        word: '查看规范文档',
        pass: '通过',
        reject: '驳回',
        over: '审核完成',
        imgList: '图片列表',
        zhang: '张',
        sx: "筛选",
        reRE: '驳回原因：',
        markList: '标注列表',
        clear: '清除所有',
        del: '删除',
        chooseClass: '请选择图片分类',
        cacel: "取消分类",
        style: '样式设置',
        line: "线宽：",
        tc: '填充透明度：',
        kuang: '标注框：',
        name: '标注名：',
        grid: '网格：',
        color: '颜色提取：',
        selectT: '请选择标注标签',
        addTag1: '请先添加标签',
        inputRe: '请输入驳回原因',
        auRes: '审核结果',
        reImgC: '驳回图片数',
        noImgC: '未确认图片数',
        passAll: '点击通过所有未确认图片',
        preMark: '预标注训练',
        surePre: '是否要覆盖当前标注，并开始预标注？',
        yes: '覆盖当前标注',
        no: '不覆盖当前标注',
        success: 'AI自动标注完成！',
        error: 'AI自动标注失败！',
        sccg: "收藏成功",
        qxsc: '取消收藏成功',
        sureDelT: '确认删除该标签？',
        sureMove: '确认将该图片移入',
        isLast: '已经是最后一张了',
        noMark: '未进行标注或修改',
        onPre: '当前任务正在进行AI自动标注',
        huanyuan: '还原大小',
        sureDelAll: '确定清除当前图片的所有标签？',
    },
    model: {
        input: '请输入模型ID或名称或任务名搜索',
        id: '模型编号',
        projName: "项目名称",
        taskId: '任务ID',
        pt: '使用平台',
        show: "查看",
        modelInfo: '模型信息',
        modelTest: '模型测试',
        study: '深度学习训练指标',
        study1: '训练指标',
        c1: '差',
        c2: '中',
        c3: '优',
        zql: '准确率',
        jcl: '检出率',
        pjjd: '平均精度',
        leftTime: '剩余时间',
        studyStatus: '训练状态',
        studyJD: '训练进度',
        xxxx: '详细信息',
        studyUser: '发起人',
        studyTime: '训练时间',
        ddcs: '迭代次数',
        studySize: '目标大小',
        imgCount: '训练图片数量',
        file: '模型文件',
        tagStudy: '标签训练指标',
        zb: "指标",
        noOver: '训练未完成',
        noBegin: '训练未开始',
        wait: '等待中',
        stop: '训练停止',
        abnormal: '训练异常停止',
        finished: '训练完成',
        training: '训练中',
        starting: '训练启动中',
        canShow: '(训练完成可查看标签训练指标)',
        sureDel: '此操作将永久删除该训练计划, 是否继续?',
        upImg: "临时上传图片",
        taskImg: '任务图片',
        bdImg: '本地图片',
        zzjz: '正在加载',
        jjsb: '加载失败',
        zsyt: "展示原图",
        gbyt: "关闭原图",
        testC: '待测试数量：',
        begin: '开 始 测 试',
        noTZ: '图片未检测到特征',
        stop1: '停止',
        zhibiao: '指标(%)',
        modelIndex: '模型训练指标',
        chooseImg: '请选择图片',
        max50: '当前限制选择50个文件',
        modelName: '模型名称',
        useTime:'用时：',
        fz: '分钟',
        mayTime: '预估耗时',
    },
    login: {
        login: "登 录",
        uLogin: '账号登录',
        pLogin: '手机验证',
        enAcc: '请输入账号',
        enPwd: '请输入密码',
        drop: '拖动滑块验证',
        noAcc: '没有账号？',
        listRe: '立即注册',
        forget: "忘记密码？",
        pDrop: "请拖动滑块验证",
        cantE: '账号或密码不能为空',
        enP: '请输入手机号',
        enC: '请输入验证码',
        send: '发送验证码',
        send1: '重新发送(',
        send2: '重新发送',
        acc: '账号',
        ph: '手机号',
        code: '验证码',
        pwd: '密码',
        pwd1: '确认密码',
        pwd2: '两次密码不一致',
        pwd3: '请再次输入密码',
        acc1: "请输入账号名且为英文",
        rePwd: '重置密码',
        regist: '注 册',
        name: '真实姓名',
        company: '公司',
        enCompany: '请输入公司名称',
        email: '邮箱',
        enEmail: '请输入正确格式的邮箱',
        hasA: '已有账号？',
        listL: '立即登录',
        Aph: "请输入正确格式的手机号",
        min6: '长度不能小于6位',
        Ac: '请输入正确的验证码',
        pass: '验证通过',
        ssr: '森赛睿',
        ai: 'AI云服务平台',
        p1: '轻松驾驭AI视觉，无需AI知识或编程经验！',
        p2: '即刻———',
        p3: '轻松使用。'
    },
    userhome: {
        userInfo: '用户信息',
        editInfo: '修改基础信息',
        editPh: '修改手机号',
        newPh: "请输入新手机号",
        old: '手机号与原手机号一致'
    },
    guide: {
        pre: "上一步",
        next: "下一步",
        finish: "完成",
        quit: "退出",
        b1: '确认开启新手指引吗？',
        b2: '确认开启任务详情页面新手指引？',
        b3: '确认开启图片标注页面新手指引？',
        proj1: '第一步，我们需要点击此处，进入项目页面下。',
        proj2: '点击‘新建项目’按钮创建项目,填写相关内容，点击确认，完成创建。',
        proj3: '在这里找到刚才新建的项目，并点击进入该项目下。点击进入一个项目后，可开启下一步引导。',
        task1: '进入项目后，可在此页面新建任务。',
        task2: '点击这里新建一个任务,并填写好相关内容，点击确认完成创建。',
        task3: '在这里找到刚才新建的任务，点击进入任务。然后在任务里上传图片，开始标注！',
        img1: '在此页面，我们可以上传图片、对图片进行分类、对图片进行筛选查看、添加标签、添加图片的分类、查看训练的模型、上传已经标注好的项目图片压缩包。。。。下面就开始一一介绍吧！',
        img2: '进入这个页面后，我们一般先需要先创建一些分类，然后将不同的图片上传到不同的分类里面，方便图片的筛选查看以及区分标注。',
        img3: '在这里创建分类，鼠标放在一个分类后面的三个点上，然后点击加号。即可在此分类下创建一个分类。创建好分类之后，点击选中此分类，再点击上传图片，即可将图片上传到此分类里。',
        img4: '在这里上传图片，上传图片前要先选择好要上传到哪个分类里哦，不选择默认上传到未分类里。',
        img5: '上传的图片会展示到这里，点击图片即可进入标注页面，但是在标注之前，我们需新建一些标签。',
        img6: '在这里新建标签，点击加号，填写好标签信息，即可新建一个标签。',
        img7: '标签创建完成之后，在这里点击一个图片，即可进入标注页面，开始标注。',
        img8: '待图片标注完成之后，点击此按钮，选择要训练的图片以及标签后，即可开始训练模型。',
        img9: '开始训练之后，系统会生成一条训练计划，点击"模型"按钮，即可切换到训练计划列表页面，点击查看，可查看训练的详细信息。',
        mark1: '在此页面，我们可对图片进行标注或者分类，首先，先介绍一下各区域的功能。',
        mark2: '这里是功能列表区域，标注相关的功能都在这里。',
        mark3: '这里是图片列表区域，此处展示任务内的图片，可进行筛选展示，点击选中一张图片，即可将标注区域的图片切换为此图片，蓝色边框的图片为已标注图片，红色边框为当前选中的图片，灰色边框为未标注图片。',
        mark4: '这里是图片标注区域，点击上方的标注框按钮，将鼠标移入此处，即可对图片进行标注。',
        mark5: '这里展示当前图片的标注信息列表，可对标注信息进行修改、替换、删除操作',
        mark6: '这里可对标注框进行样式设置，如更改标注框的粗细、填充透明度等等。',
        mark7: '页面各区域功能介绍完毕，下面对上方功能列表区域进行详细介绍。',
        mark8: '这里展示了当前图片的名称以及宽高信息。',
        mark9: '点击此按钮，可下载当前图片。',
        mark10: '点击此按钮，可将当前图片移入其他分类中。',
        mark11: '点击此按钮，可筛选出所有收藏的图片。',
        mark12: '点击此按钮，切换上一张图片，若当前图片进行了标注或者修改，点击此按钮，系统会自动保存标注信息。',
        mark13: '点击此按钮，切换为移动状态，鼠标进入标注区域，摁下并移动鼠标，即可移动图片位置。',
        mark14: '点击此按钮，切换为标注状态，鼠标进入标注区域，摁下并移动鼠标，即可对图片进行标注。',
        mark15: '点击此按钮，可撤销上一个标注框。',
        mark16: '点击此按钮，可放大标注区域的图片。',
        mark17: '点击此按钮，可缩小标注区域的图片。',
        mark18: '点击此按钮，切换下一张图片，若当前图片进行了标注或者修改，点击此按钮，系统会自动保存标注信息。',
        mark19: '点击此按钮，可保存当前图片标注信息。',
        mark20: '点击此按钮，可收藏当前图片。',
        mark21: '点击此按钮，可给当前任务添加新的标签。',
        mark22: '点击此按钮，可上传、查看或者删除规范文档。',
    },
    main: {
        oneTo20: '长度在 1 到 20 个字符',
        oneTo200: '长度在 1 到 200 个字符',
        title: '提示',
        caozuo: '操作',
        guifan: '请规范填写',
        warn: '警告',
        dan: '不可输入英文单引号和双引号',
        xiahua: '请输入正确格式(只能英文数字和下划线)！',
        success: '成功',
        fail: '失败',
        wu: '暂无数据',
        wait: '等待上传',
        nodata: '无',
    }
};